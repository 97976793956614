
  import { defineComponent, ref, onMounted, computed } from 'vue';
  import { useRouter } from 'vue-router';
  import { Form } from 'vee-validate';
  import { setCurrentPageBreadcrumbs } from '@/core/helpers/breadcrumb';
  import { useI18n } from 'vue-i18n/index';
  import { useStore } from 'vuex';
  import { Actions } from '@/store/enums/StoreEnums';
  import { useAbility } from '@casl/vue';
  import Swal from 'sweetalert2/dist/sweetalert2.js';
  import Editor from '@tinymce/tinymce-vue';

  export default defineComponent({
    name: 'terms-conditions-editing',
    components: {
      editor: Editor,
      Form,
    },

    async setup() {
      const { t, te } = useI18n();
      const store = useStore();
      const router = useRouter();
      const { can } = useAbility();
      const submitButton = ref<HTMLButtonElement | null>(null);
      const translate = (text) => {
        if (te(text)) {
          return t(text);
        } else {
          return text;
        }
      };

      onMounted(() => {
        setCurrentPageBreadcrumbs(translate('termsAndConditionsUpdating'), [
          translate('settings'),
        ]);
      });

      const htmlEn = ref(store.getters.TermsAndConditionsView.htmlEn);
      const htmlAr = ref(store.getters.TermsAndConditionsView.htmlAr);

      const goBack = () => {
        router.back();
      };
      const onSubmitUpdate = async () => {
        if (submitButton.value) {
          // eslint-disable-next-line
          submitButton.value!.disabled = true;
          submitButton.value.setAttribute('data-kt-indicator', 'on');
        }

        await store.dispatch(Actions.UPDATE_TERMS_AND_CONDITIONS, {
          id: 1,
          data: { htmlAr: htmlAr.value, htmlEn: htmlEn.value },
        });
        const [errorName] = Object.keys(store.getters.getErrors);
        const error = store.getters.getErrors[errorName];

        if (error) {
          Swal.fire({
            text: translate(error[0]),
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText: translate('tryAgainExcl'),
            customClass: {
              confirmButton: 'btn fw-bold btn-light-danger',
            },
          });
          //Deactivate indicator
          submitButton.value?.removeAttribute('data-kt-indicator');
          // eslint-disable-next-line
          submitButton.value!.disabled = false;
        } else {
          Swal.fire({
            text: translate('SUCCESSFULLY_UPDATED_TERMS_AND_CONDITIONS'),
            icon: 'success',
            buttonsStyling: false,
            confirmButtonText: translate('ok'),
            customClass: {
              confirmButton: 'btn btn-light-primary',
            },
          }).then(() => {
            router.push({ name: 'terms-conditions-overview' });
          });
        }
      };

      store.dispatch(Actions.REMOVE_BODY_CLASSNAME, 'page-loading-over');

      return {
        termsAndConditions: computed(
          () => store.getters.TermsAndConditionsView
        ),
        translate,
        goBack,
        submitButton,
        onSubmitUpdate,
        htmlEn,
        htmlAr,
        can,
      };
    },
  });
